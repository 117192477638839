import { Inject } from "@angular/core";
import { IbaseClass } from "./ibase-class";
import { IbaseService } from "../base-service/ibase-service";
import { environment } from "src/environments/environment";
import { api } from "../services/api";
import { DOCUMENT } from "@angular/common";
import { Events, EventsName } from "../model/event";
import { Subscription } from "rxjs";

export interface ErrorObj {
  code: number;
  message: string;
}
export interface ResFormat {
  data: any;
  error: ErrorObj;
  status: boolean;
}

export abstract class BaseClass<T> implements IbaseClass<any> {
  abstract getServices(): IbaseService<T>;
  protected subs: Subscription[] = [];

  public env = environment;
  public api = api;
  public userData = this.getLocalItem('user');
  public menu = this.getLocalItem('menu');
  public sideMenu = this.getLocalItem('sideMenu');
  public window: any;
  public isLoad = false;
  public isSubmit = false;
  public webSocketUrl: string = '';
  public navMarquee = '';
  public events = {
    sports: Events.Sports.split(",").map((e) => { return Number(e) }),
    races: Events.Races.split(",").map((e) => { return Number(e) }),
    casino: Events.Casino.split(",").map((e) => { return Number(e) }),
    casino1: Events.Casino1.split(",").map((e) => { return Number(e) }),
    iCasino: Events.ICasino.split(",").map((e) => { return Number(e) }),
    intCasino: Events.IntCasino.split(",").map((e) => { return Number(e) }),
    binary: Events.Binary.split(",").map((e) => { return Number(e) }),
    sportsBook: Events.SportsBook.split(",").map((e) => { return Number(e) }),
  }
  public eventsName = this.genEventsName();
  public dateKeys = ['created_at', 'updated_at', 'matched_at', 'requested_at', 'open_date', 'market_start_time']
  public dateFormateKeys = ['created_at_format', 'updated_at', 'matched_at', 'requested_at', 'open_date', 'market_start_time']
  public eventTypesObj: any = this.getMenuObj();
  
  // public appTimeZone: any;
  // public defaultTimeZone: string = 'GMT +05:30';
  // public selectedTimeZone: string;


  constructor(@Inject(DOCUMENT) public document: Document) {
    this.window = this.document.defaultView;
    //this.appTimeZone = this.getLocalItem('appTimeZone', true);
    //this.selectedTimeZone = (this.appTimeZone?.value)? this.appTimeZone.value : this.defaultTimeZone;
  }

  getLocalItem(key: string, isParse: boolean = true) {
    return (isParse) ? JSON.parse(localStorage.getItem(`${this.env.panel + key}`) as any) : localStorage.getItem(`${this.env.panel + key}`) as any;
  }

  setLocalItem(key: string, data: any, isStringify: boolean = true) {
    let item = (!isStringify) ? data : JSON.stringify(data);
    localStorage.setItem(`${this.env.panel + key}`, item);
  }

  getMenuObj() {
    this.menu = this.getLocalItem('menu');
    return this.menu ? Object.assign({}, ...this.menu.map((elt: any) => ({ [elt?.id]: elt.name }))) : {}
  }

  genEventsName() {
    let menu = this.getLocalItem('menu');
    return menu ? Object.assign({}, ...menu.map((elt: any) => ({ [elt?.id]: (this.events.iCasino.includes(elt?.id)) ? EventsName.ICasino : (this.events.casino1.includes(elt?.id)) ? EventsName.Casino1 : (this.events.sportsBook.includes(elt?.id)) ? EventsName.SportsBook : elt.name }))) : {}
  }

  isLogin() {
    return (localStorage.getItem(`${this.env.panel}id_token`)) ? true : false;
  }

  isLoginWithDemo() {
    this.userData = this.getLocalItem('user');
    return (this.userData?.dr) ? true : false;
  }

  UTC_to_Local_timeZone(items: any) {

    if (Array.isArray(items)) {
      items.forEach(element => {
        Object.keys(element).forEach((key, index) => {

          if (this.dateKeys.includes(key)) {
            if (element[key].includes('T')) {
              element[key] = element[key].replace('T', ' ').replace('.000000Z', '')
            }
            element[key] = new Date(element[key] + ' UTC').toString();
          }
        });
      });

    } else if (typeof (items) == 'object') {
      Object.keys(items).forEach(key => {
        if (this.dateKeys.includes(key)) {
          if (items[key].includes('T')) {
            items[key] = items[key].replace('T', ' ').replace('.000000Z', '')
          }
          items[key] = new Date(items[key] + ' UTC').toString();
        }
      });
    }
    return items;
  }

  JSON_to_URLEncoded(items: any, keyGen: any = '') {
    let list: any[] = [];
    if (typeof (items) == 'object') {
      Object.keys(items).forEach(key => {
        list.push(`${key}=${encodeURIComponent(items[key])}`)
      });
    } else if (Array.isArray(items)) {
      items.forEach(element => {
        list.push(`${keyGen}=${encodeURIComponent(element)}`)
      });
    }

    return list.join('&');
  }

  string_to_JSON(res: any) {
    const uniqueSet = new Set(res);
    res = [...uniqueSet];
    res = res.filter((el: any) => {
      return el != null;
    });
    res.forEach((element: any, i: any) => {
      res[i] = JSON.parse(element);
    });

    return res;
  }

  pipe_string_to_JSON(res: any) {
    const uniqueSet = new Set(res);
    res = [...uniqueSet];
    res = res.filter((el: any) => {
      return el != null;
    });
    res.forEach((element: any, i: any) => {
      res[i] = element.split("|");
    });

    return res;
  }

  getLastWeek() {
    var today = new Date();
    var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
  }
  getLast15Days() {
    var today = new Date();
    var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14);
    return lastWeek;
  }
  getBeforeThreeDays() {
    var today = new Date();
    var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 3);
    return lastWeek;
  }

  groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K) {
    const map = new Map();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  // for fancy tab
  isInclude(tabVals: any, priority: any) {
    return tabVals.includes(priority);
  }

  // for fancy tab
  isCommon(a: any, b: any) {
    return (a && Array.isArray(a)) ? a.filter((o1: any) => b.some((o2: any) => o1 == o2)).length == 0 : [];
  }

  kFormatter(num: any) {
    if (num) {
      //old code
      //const n = (Math.abs(num) / 1000).toFixed(1);
      //return Math.abs(num) > 999 ? Math.sign(num) * parseFloat(n) + 'k' : Math.sign(num) * Math.abs(num);

      //new code
      if(Math.abs(num) >= 100000){
        const n = (Math.abs(num) / 100000).toFixed(1);
        return Math.abs(num) > 99999 ? Math.sign(num) * parseFloat(n) + ' lakh' : Math.sign(num) * Math.abs(num);
      } else if(Math.abs(num) >= 1000) {
        const n = (Math.abs(num) / 1000).toFixed(1);
        return Math.abs(num) > 999 ? Math.sign(num) * parseFloat(n) + ' k' : Math.sign(num) * Math.abs(num);
      } else {
        return num;
      }
    } else {
      return '-';
    }
  }

  roundVal(e: any) {
    return e ? ~~e : e
  }

  positiveVal(e: any) {
    return e ? Number(Math.abs(e)) : e;
  }

  removeLocalStorage() {
    const items = { ...localStorage };
    Object.entries(items).forEach(([key, value]) => {
      if (key.includes(environment.panel)) {
        localStorage.removeItem(key)
      }
    });
  }

  decimalFixed(val: any, n: any) {
    return Number(val).toFixed(n);
  }

  subtractMinutes(numOfMinutes: number, date = new Date()) {
    date.setMinutes(date.getMinutes() - numOfMinutes);
    return date;
  }

  redirectCompititionString(str: string) {
    return str.replace(/ /g, '-').toLowerCase();
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  preventClick(e: any) {
    e.stopPropagation();
  }

  gotoDiv(location: any) {
    let el = document.getElementById(location) as HTMLElement;
    if (el)
      el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }

  fixedPostDecimal(e: any, num: number = 2) {
    return e ? parseFloat(e).toFixed(num) : e
  }

}
