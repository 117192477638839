<div class="modal-body">
    <button type="button" class="close" (click)="bsModalRef.hide()">
       <span aria-hidden="true">&times;</span>
    </button>
 
    <form class="change__password__ui" [formGroup]="formData" (ngSubmit)="onSubmit()">
       <h3>Change Password</h3>

         <div class="input-group">
            <span class="input-group-text"><i class="mdi mdi-lock"></i></span>
            <input [type]="oldPasswordTextType ? 'text' : 'password'" formControlName="old_password" class="form-control" name="" placeholder="Old Password">
            <a class="eye-on-off" (click)="toggleOldPasswordFieldTextType()"><i class="mdi " [ngClass]="{'mdi-eye': !oldPasswordTextType, 'mdi-eye-off': oldPasswordTextType }"></i></a>
            <small *ngIf="submitted && f.old_password.errors?.required" class="error">Required</small>
         </div>
         <div class="input-group">
            <span class="input-group-text"><i class="mdi mdi-lock"></i></span>
            <input [type]="newPasswordTextType ? 'text' : 'password'" formControlName="new_password" class="form-control" name="" id="passwordIpt" placeholder="New Password">
            <a class="eye-on-off" (click)="toggleNewPasswordFieldTextType()"><i class="mdi " [ngClass]="{'mdi-eye': !newPasswordTextType, 'mdi-eye-off': newPasswordTextType }"></i></a>
            <small *ngIf="submitted && f.new_password.errors?.required" class="error">Required</small>
            <small *ngIf="submitted && f.new_password.errors?.minlength" class="error">At least 6 characters</small>
            <small *ngIf="submitted && !f.new_password.errors?.minlength && f.new_password.errors?.pattern" class="error">The New Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number</small>
           <!-- <a class="eye-on-off"><i class="mdi mdi-eye-off"></i></a> -->
         </div>
         <div class="input-group">
            <span class="input-group-text"><i class="mdi mdi-lock"></i></span>
            <input [type]="confirmPasswordTextType ? 'text' : 'password'" formControlName="new_password_re" class="form-control" name="" id="passwordIpt" placeholder="Re-enter Password">
            <a class="eye-on-off" (click)="toggleConfirmPasswordFieldTextType()"><i class="mdi " [ngClass]="{'mdi-eye': !confirmPasswordTextType, 'mdi-eye-off': confirmPasswordTextType }"></i></a>
            <small *ngIf="submitted && f.new_password_re.errors?.required" class="error">Required</small>
            <small *ngIf="submitted && f.new_password_re.errors?.mustMatch" class="error">Passwords mismatch</small>
            <!-- <a class="eye-on-off"><i class="mdi mdi-eye"></i></a> -->
         </div>
         <button type="submit" [disabled]="isLoad" class="btn-cp" style="color: #000;">change password &nbsp; &Tab; <i *ngIf="isLoad" class="fa fa-spinner"></i></button>
 
    </form>
 
 </div>