import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BaseClass } from 'src/app/@shared/base-class/base-class';
import { IbaseService } from 'src/app/@shared/base-service/ibase-service';
import { MessageService } from 'src/app/@shared/message/message.service';
import { CommonService } from 'src/app/@shared/services/common.service';
import { WebserviceService } from 'src/app/@shared/services/webservice.service';
import { LoginComponent } from 'src/app/auth/login/login.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends BaseClass<any> implements OnInit {

  formData!: FormGroup;
  @Input() isModal!: any;
  errors: any;
  oldPasswordTextType = false;
  newPasswordTextType = false;
  confirmPasswordTextType = false;
  submitted = false;
  // @Output() openLogin = new EventEmitter();

  constructor(public service: WebserviceService,
    protected fb: FormBuilder,
    public router: Router,
    public bsModalRef: BsModalRef,
    public _message: MessageService,
    public _common: CommonService,
    protected modalService: BsModalService
  ) {
    super(document);
  }

  ngOnInit(): void {
    this.initFormData();
  }

  initFormData() {
    this.formData = this.fb.group({
      old_password: ['', Validators.required],
      new_password: ['', [Validators.required, Validators.minLength(6)]],
      new_password_re: ['', Validators.required],
    }, {
      validator: this._common.mustMatch('new_password', 'new_password_re'),
    });
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  get f() {
    return this.formData.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.formData.invalid) {
      return;
    }

    this.isLoad = true;
    let req = this.JSON_to_URLEncoded(this.formData.value);
    this.getServices().Post(`${this.env.baseUrl + this.api.changePass}`, req).subscribe(
      (res) => {
        if (res.status == true) {
          this._message.succmessage(res.data.message);
          this.bsModalRef.hide();
          this.removeLocalStorage();
          this.router.navigate(['/home']);
          //this.openLogin('');
        } else {
          this.errors = res.data;
          this._message.errmessage(res.error.message);
        }
        this.isLoad = false;
      },
      (err) => {
        console.log(err);
        this.isLoad = false;
      });
  }

  openLogin(e: any) {
    this._common.openModalWithComponent('', LoginComponent, 'login_modal modal-dialog-centered')
  }

  toggleOldPasswordFieldTextType() {
    this.oldPasswordTextType = !this.oldPasswordTextType;
  }

  toggleNewPasswordFieldTextType() {
    this.newPasswordTextType = !this.newPasswordTextType;
  }

  toggleConfirmPasswordFieldTextType() {
    this.confirmPasswordTextType = !this.confirmPasswordTextType;
  }
}
