import { Component, Injectable, SecurityContext, TemplateRef } from '@angular/core';
// import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ForceChangePasswordComponent } from '../components/force-change-password/force-change-password.component';
import { MessageService } from '../message/message.service';
import { ChangePasswordComponent } from 'src/app/pages/change-password/change-password.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(public sanitizer: DomSanitizer, public location: Location, public _shared: SharedService, protected modalService: BsModalService, public _message:MessageService) {
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  diff(a: any, b: any) {
    // return (a > b) ? 'up' : (a < b) ? 'down' : '';
    return (a > b) ? 'blink' : (a < b) ? 'blink' : '';
  }

  secureUrl(url: any) {
    return this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustResourceUrl(url))
  }

  checkForForceChangePassword(res: any) {
    if (res.error.code == 1003) {
      const items = { ...localStorage };
      Object.entries(items).forEach(([key, value]) => {
        if (key.includes(environment.panel)) {
          localStorage.removeItem(key)
        }
      });
      localStorage.setItem(`${environment.panel}id_token`, `${res.data.token_type} ${res.data.access_token}`);
      //this.openModalWithComponent('', ForceChangePasswordComponent);
      this.openModalWithComponent('', ChangePasswordComponent, 'cp_modal modal-dialog-centered');
    }
  }

  bsModalRef!: BsModalRef;
  openModalWithComponent(key: any, component: any, cusClass:any = '') {
    const initialState: any = { key: key };
    this.bsModalRef = this.modalService.show(component, {
      initialState,
      class: cusClass
    });
    this.bsModalRef.content.closeBtnName = 'Close';

    // this.bsModalRef.content.event.subscribe((res: any) => {
    // this.itemList.push(res.data);
    // });
  }

  compareData(newData: any, listData: any) {

    if (Array.isArray(listData) && newData.length > 0) {
      newData.forEach((element: any, ni: any) => {
        if (listData[ni]) {
          if (element.market_type_id == "BOOK_MAKER") {

            element.book_maker_odds.forEach((runEle: any, ri: any) => {
              // random number for testing
              // listData[ni].book_maker_odds[ri].back = Math.floor(Math.random() * 90 + 10)
              // runEle.back = Math.floor(Math.random() * 90 + 10)
              if (listData[ni]?.book_maker_odds?.length > 0) {
                for (let i = 0; i < 3; i++) {
                  let Lback = (i == 0) ? listData[ni]?.book_maker_odds[ri]?.back : listData[ni]?.book_maker_odds[ri][`back${i}`]
                  let Nback = (i == 0) ? runEle.back : runEle[`back${i}`]

                  if (Lback && Nback) {
                    let key = this.diff(Nback, Lback)
                    runEle[`bKey${(i == 0) ? '' : i}`] = key;
                  }

                  let Llay = (i == 0) ? listData[ni]?.book_maker_odds[ri]?.lay : listData[ni]?.book_maker_odds[ri][`lay${i}`]
                  let Nlay = (i == 0) ? runEle.lay : runEle[`lay${i}`]

                  if (Llay && Nlay) {
                    let key = this.diff(Nlay, Llay)
                    runEle[`lKey${(i == 0) ? '' : i}`] = key;
                  }
                }
              }
            });

          } else if (element.market_type_id == "FANCY") {

            if (listData[ni]) {
              // random number for testing
              // listData[ni].back_price = Math.floor(Math.random() * 90 + 10)
              // element.back_price = Math.floor(Math.random() * 90 + 10)


              for (let i = 0; i < 3; i++) {
                let Lback = (i == 0) ? listData[ni].back_price : listData[ni][`back_price${i}`]
                let Nback = (i == 0) ? element.back_price : element[`back_price${i}`]

                if (Lback && Nback) {
                  let key = this.diff(Nback, Lback)
                  element[`bKey${(i == 0) ? '' : i}`] = key;
                }
                let Llay = (i == 0) ? listData[ni].lay_price : listData[ni][`lay_price${i}`]
                let Nlay = (i == 0) ? element.lay_price : element[`lay_price${i}`]

                if (Llay && Nlay) {
                  let key = this.diff(Nlay, Llay)
                  element[`lKey${(i == 0) ? '' : i}`] = key;
                }
              }
            }

          } else {
            if (element.status == 1) {
              element.runners.forEach((runEle: any, ri: any) => {
                if (listData[ni]) {
                  runEle.ex.availableToBack.forEach((bk: any, bi: any) => {
                    if (listData[ni].runners[ri]) {
                      if (listData[ni]?.runners[ri]?.ex.availableToBack.length > 0 && listData[ni]?.runners[ri]?.ex.availableToBack[bi]?.price) {
                        let key = this.diff(bk.price, listData[ni]?.runners[ri]?.ex.availableToBack[bi]?.price)
                        bk['key'] = key;
                      }
                    }
                  });

                  runEle.ex.availableToLay.forEach((ly: any, li: any) => {
                    if (listData[ni].runners[ri]) {
                      if (listData[ni]?.runners[ri]?.ex.availableToLay.length > 0 && listData[ni]?.runners[ri]?.ex.availableToLay[li]?.price) {
                        let key = this.diff(ly.price, listData[ni]?.runners[ri]?.ex.availableToLay[li]?.price)
                        ly['key'] = key;
                      }
                    }
                  });
                }
              });
            }
          }
        }

      });

      return newData;
    } else {
      return newData;
    }

  }

  arrangeGetMarketNewData(res: any, items: any) {
    // console.log(res);
    // console.log(items);

    let resOdds: any = {};
    res.forEach((odd: any) => {
      if (odd.length > 5 && odd[items?.market_odds_keys?.market_id][0] == '1') {

        // Market ID first char 1(Match odds) => Follow market_odds_keys
        let tempOdds: any = {};
        tempOdds["marketId"] = odd[items?.market_odds_keys?.market_id];
        tempOdds["status"] = odd[items?.market_odds_keys?.status];
        tempOdds["inplay"] = odd[items?.market_odds_keys?.inplay];
        tempOdds["totalMatched"] = odd[items?.market_odds_keys?.total_matched];
        tempOdds["numberOfRunners"] = 0;
        tempOdds["runners"] = [] as any;
        // let runners: any[] = [];
        for (let i = items?.market_odds_keys?.skip_keys; i < odd.length; i += items?.market_odds_keys?.runner_key) {
          tempOdds["numberOfRunners"]++;
          let rData = {
            "selectionId": odd[i],
            "status": odd[i + 1],
            "ex": {
              "availableToBack": [] as any,
              "availableToLay": [] as any,
            }
          }
          rData.ex.availableToBack[0] = { price: odd[i + 2] ? odd[i + 2] : '-', size: odd[i + 3] ? odd[i + 3] : '-' };
          rData.ex.availableToBack[1] = { price: odd[i + 4] ? odd[i + 4] : '-', size: odd[i + 5] ? odd[i + 5] : '-' };
          rData.ex.availableToBack[2] = { price: odd[i + 6] ? odd[i + 6] : '-', size: odd[i + 7] ? odd[i + 7] : '-' };

          rData.ex.availableToLay[0] = { price: odd[i + 8] ? odd[i + 8] : '-', size: odd[i + 9] ? odd[i + 9] : '-' };
          rData.ex.availableToLay[1] = { price: odd[i + 10] ? odd[i + 10] : '-', size: odd[i + 11] ? odd[i + 11] : '-' };
          rData.ex.availableToLay[2] = { price: odd[i + 12] ? odd[i + 12] : '-', size: odd[i + 13] ? odd[i + 13] : '-' };
          tempOdds["runners"].push(rData);
        }

        resOdds[odd[items?.market_odds_keys?.market_id]] = tempOdds;

      } else if (odd.length > 5 && odd[items?.bookmaker_odds_keys?.market_id][0] == '8') {

        // Market ID first char 8 (Book Maker) => Follow bookmaker_odds_keys
        let tempOdds: any = {};
        tempOdds["id"] = odd[items?.bookmaker_odds_keys?.id];
        tempOdds["event_id"] = odd[items?.bookmaker_odds_keys?.event_id];
        tempOdds["market_id"] = odd[items?.bookmaker_odds_keys?.market_id];
        tempOdds["market_type_id"] = odd[items?.bookmaker_odds_keys?.market_type_id];
        tempOdds["min_bet"] = odd[items?.bookmaker_odds_keys?.min_bet];
        tempOdds["betting_type"] = odd[items?.bookmaker_odds_keys?.betting_type];
        tempOdds["max_bet"] = odd[items?.bookmaker_odds_keys?.max_bet];
        tempOdds["max_profit"] = odd[items?.bookmaker_odds_keys?.max_profit];
        tempOdds["max_market_profit"] = odd[items?.bookmaker_odds_keys?.max_market_profit];
        tempOdds["bet_delay"] = odd[items?.bookmaker_odds_keys?.bet_delay];
        tempOdds["bet_allow"] = odd[items?.bookmaker_odds_keys?.bet_allow];
        tempOdds["check_volume"] = odd[items?.bookmaker_odds_keys?.check_volume];
        tempOdds["status"] = odd[items?.bookmaker_odds_keys?.status];
        tempOdds["message"] = odd[items?.bookmaker_odds_keys?.message];
        tempOdds["title"] = odd[items?.bookmaker_odds_keys?.title];
        tempOdds["volume_multi"] = odd[items?.bookmaker_odds_keys?.volume_multi];
        tempOdds["is_api"] = odd[items?.bookmaker_odds_keys?.is_api];
        tempOdds["api_bm_ref"] = odd[items?.bookmaker_odds_keys?.api_bm_ref];
        tempOdds["numberOfRunners"] = 0;
        tempOdds["book_maker_odds"] = [] as any;
        // let runners: any[] = [];
        for (let i = items?.bookmaker_odds_keys?.skip_keys; i < odd.length; i += items?.bookmaker_odds_keys?.bm_odd_key) {
          tempOdds["numberOfRunners"]++;
          let rData = {
            "id": odd[i],
            "name": odd[i + 1],
            "event_id": odd[i + 2],
            "selection_id": odd[i + 3],
            "runner_id": odd[i + 4],
            "book_maker_id": odd[i + 5],
            "back": odd[i + 6] ? odd[i + 6] : '-',
            "back_volume": odd[i + 7] ? odd[i + 7] : '-',
            "lay": odd[i + 8] ? odd[i + 8] : '-',
            "lay_volume": odd[i + 9] ? odd[i + 9] : '-',
            "ball_running": odd[i + 10],
            "suspended": odd[i + 11],
            "status": odd[i + 12],
          }
          tempOdds["book_maker_odds"].push(rData);
        }

        resOdds[odd[items?.bookmaker_odds_keys?.market_id]] = tempOdds;

      } else if (odd.length > 5 && odd[items?.market_odds_keys?.market_id][0] == '9') {

        // Market ID first char 8 (Fancy) => Follow fancy_odds_keys
        let tempOdds: any = {};
        Object.keys(items?.fancy_odds_keys).forEach(key => {
          tempOdds[`${key}`] = odd[items?.fancy_odds_keys[key]];
        });
        resOdds[odd[items?.fancy_odds_keys?.market_id]] = tempOdds;

      }
    });

    return resOdds;
  }

  compareAndSetEventData(oldItems: any, resItems: any) {
    Object.keys(resItems.event).forEach(key => {
      oldItems.event.event = resItems?.event?.event;

      if (typeof (resItems.event[key]) == 'object' && resItems.event[key]?.market_id && oldItems.event[key]?.market_id) {
        oldItems.event[key] = resItems.event[key];
      } else if (Array.isArray(resItems.event[key])) {
        oldItems.event[key] = resItems.event[key];
      }
    });
    return oldItems;
  }

  createMarketIds(items: any) {
    let market: any[] = [];
    Object.keys(items).forEach(key => {
      if (typeof (items[key]) == 'object' && items[key]?.market_id) {
        market.push(items[key]?.market_id)
      } else if (Array.isArray(items[key])) {
        items[key].forEach((element: any) => {
          if (element.market_id) {
            market.push(element?.market_id)
          }
        });
      }
    });

    const uniqueSet = new Set(market);
    market = [...uniqueSet];

    return market;
  }

  createMarketIdList(items: any) {
    let market: any[] = [];
    Object.keys(items).forEach(key => {
      if (typeof (items[key]) == 'object' && items[key]?.market_id) {
        market.push('market_ids[]=' + encodeURIComponent(items[key]?.market_id))
      } else if (Array.isArray(items[key])) {
        items[key].forEach((element: any) => {
          if (element.market_id) {
            market.push('market_ids[]=' + encodeURIComponent(element?.market_id))
          }
        });
      }
    });

    const uniqueSet = new Set(market);
    market = [...uniqueSet];

    return market.join('&');
  }

  getStakeArray() {
    let stakes: any[] = [];
    var st: any;
    let stAmount: any[] = [];
    let stName: any[] = [];
    st = (localStorage.getItem(`${environment.panel}stakes`)) ? JSON.parse(localStorage.getItem(`${environment.panel}stakes`) as any) : [];
    Object.keys(st).forEach(key => {
      if (key.includes('amount')) { stAmount.push(st[key]) };
      if (key.includes('name')) { stName.push(st[key]) };
    })
    stAmount.forEach((key, i) => stakes.push({ 'name': stName[i], 'amount': stAmount[i] }));
    return stakes;
  }

  getBetSlipData(item: any, oodsIitem: any, i: any, betData: any, oi: any, bkOrLy: any, key: string, token: string, stake: any = 0) {
    // console.log(item);
    // console.log(i);
    // console.log(betData);
    // console.log("----");

    if (betData) {
      let stakes: any[] = this.getStakeArray();

      let betRunnerData: any = {};

      let betData1 = {
        data: item,
        index: i,
        bet: betData,
        side: bkOrLy,
        fancy_rate: '',
        stake: Number(stake),
        stakes: stakes,
        token: token,
        isClosed: false,
        disabled: true,
        isSubmitted: false,
        runIndex: i,
        oddsIndex: oi,
        key: key
      };

      Object.keys(oodsIitem).forEach(key => {
        if (key == 'marketId') {
          betData1.side = bkOrLy;
        } else if (key == 'market_id') {
          betData1.side = (bkOrLy.includes("b")) ? 'b' : 'l';
        }
      });
      let betData2: any = {};
      if (key == 'match_odds') {
        betData2 = {
          name: item.runners[i].name,
          market_id: item.market_id,
          selection_id: item.runners[i].selection_id,
          rate: betData.size,
          price: betData.price,
          event_id: item.event_id,
          runners: oodsIitem?.runners,
          min: item.min_bet,
          max: item.max_bet,
          cRunners: oodsIitem?.cRunners
        };
      } else if (key == 'book_makers') {
        betData2 = {
          name: betData.name,
          market_id: item.market_id,
          selection_id: betData.selection_id,
          rate: (bkOrLy.includes('b')) ? betData[`back${oi}_volume`] : betData[`lay${oi}_volume`],
          price: (bkOrLy.includes('b')) ? betData[`back${oi}`] : betData[`lay${oi}`],
          event_id: item.event_id,
          runners: oodsIitem?.book_maker_odds,
          min: oodsIitem.min_bet,
          max: oodsIitem.max_bet,
          cRunners: oodsIitem?.cRunners
        };
      } else if (key == 'fancy') {
        betData1.fancy_rate = `rate${i}`;
        betData2 = {
          name: item.name,
          market_id: item.market_id,
          selection_id: oodsIitem.selection_id,
          rate: (bkOrLy.includes('b')) ? betData[`back${oi}_rate`] : betData[`lay${oi}_rate`],
          price: (bkOrLy.includes('b')) ? betData[`back${oi}_price`] : betData[`lay${oi}_price`],
          event_id: item.event_id,
          runners: [],
          min: oodsIitem.min_bet,
          max: oodsIitem.max_bet
        };
      }

      betRunnerData = { ...betData1, ...betData2 }
      return betRunnerData;
    }

  }

  commingSoon() {
    this._message.infomessage('Comming Soon...')
  }

  back(): void {
    this.location.back()
  }
}
