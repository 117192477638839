<div class="modal-body" *ngIf="!isDemoLogin && !signUpPage">
   <button type="button" class="close" (click)="closeModal()">
      <!-- <span aria-hidden="true">&times;</span> -->
      <span aria-hidden="true"><i class="mdi mdi-home"></i></span>
   </button>

   <form [formGroup]="formGroup" (ngSubmit)="onLogin()" class="col-md-4 col-12">
      <img class="img-fluid" src="assets/img/{{env.host.name}}/logo.png">
      <div class="my-3">
         <div class="form-group">
            <input formControlName="username" type="text" class="form-control" name="">
            <label class="floating-label">Email / Username</label>
            <a class="eye-on-off">
               <i class="mdi mdi-account"></i>
            </a>
            <small *ngIf="f.username.touched && f.username.errors?.required" class="error">Required</small>
         </div>
         <div class="form-group">
            <input formControlName="password" type="password" class="form-control" name="" id="passwordIpt">
            <label class="floating-label">Password</label>
            <a class="eye-on-off" (click)="togglePasscode()"><i *ngIf="!passType" class="mdi mdi-eye"></i><i *ngIf="passType" class="mdi mdi-eye-off"></i></a>
            <small *ngIf="f.password.touched && f.password.errors?.required" class="error">Required</small>
         </div>
         <div class="btn-group">
            <button type="submit" [disabled]="formGroup.invalid || isLoad" class="v-btn">login<i *ngIf="isLoad" class="mdi mdi-loading mdi-spin"></i></button>
            <button type="button" [disabled]="isLoadDemo" (click)="loginWIthDemoId()" class="v-btn demobtn">demo login<i *ngIf="isLoadDemo" class="mdi mdi-loading mdi-spin"></i></button>
            <div class="text-right mt-1" *ngIf="env.host.signUpActive" (click)="openResetPassModal(resetpass)"><span style="cursor: pointer;">Forgot Password?</span></div>
         </div>
      </div>
      <p>For whitelabel enquiry, please contact <br> <a>{{env.host.email}}</a></p>
   </form>


</div>

<a href="javascript:void(0)" class="v-btn demobtn top-btn" [ngClass]="{'disabled' : isLoadDemo}" (click)="loginWIthDemoId()" *ngIf="isDemoLogin">demo <i *ngIf="isLoadDemo" class="mdi mdi-loading mdi-spin"></i></a>

<iframe *ngIf="signUpPage" class="embed-responsive-item signup-iframe" scrolling="yes" style="min-height: 500px;" [src]="env.host.iframeDomainUrl + 'signup/' + env.domain + env.trafficTag | safeURL" allowfullscreen="true" title="game"></iframe>


<ng-template #resetpass>
  <div class="modal-body resetpass p-0">
    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <iframe class="embed-responsive-item resetpass-iframe" scrolling="yes" style="min-height: 500px;" [src]="env.host.iframeDomainUrl + 'forgot-password/' +env.domain | safeURL" allowfullscreen="true" title="game"></iframe>
  </div>
</ng-template>